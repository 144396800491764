import React, { useEffect,useState } from 'react';
import { Link ,useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import './assets/css/style.css';

function Project() {
  const [posting, setPosting] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [activeSection, setActiveSection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 9; // jumlah item per halaman

    //   Pagination 
    const navigate = useNavigate();

    // Access the URL parameters
    const { pageNumber } = useParams();

    // Now you can use pageNumber in your component logic
    // For example, you can convert it to a number
    const currentPageNumber = parseInt(pageNumber);


    useEffect(() => {
        document.title = "MerapiKoding | Project";
    }, []);


    useEffect(() => {
        getPosting();
      }, [currentPage]);

    const getPosting = async () => {
        const response = await axios.get(`/landingpost?page=${currentPage}&limit=${limit}`);
        if (response.data.length) {
          setPosting(response.data);
          console.log(response.data.length);
          // console.log(response.data);
        }
        const totalResponse = await axios.get(`/posting/total`);
        const totalItems = totalResponse.data[0]?.jumlah || 0;
        setTotal(Math.ceil(totalItems / limit));
      

      }

        // handle pagination
        const Pagination = (page) => {
            setCurrentPage(page);
            navigate(`/Portofolio/page/${page}`);
        };
        
        // membuat list item pagination
        const pageNumbers = [];
        for (let i = 1; i <= Total; i++) {
            pageNumbers.push(
            
            <Link to={`/Portofolio/page/${i}`} onClick={() => Pagination(i)} className={currentPage === i ? 'active' : ''}>{i}</Link>
            );
        }

    return (
        <div>
            <main className="flex-shrink-0">
                {/* ======= Breadcrumbs ======= */}
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Portfolio Details</h2>
                        <ol>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>Portfolio Details</li>
                        </ol>
                    </div>
                    </div>
                </section>
                {/* End Breadcrumbs */}
                {/* Projects Section*/}
                <section className="py-5">
                    <div className="container px-5 mb-5">
                        <div className="text-center mb-5">
                            <h1 className="display-5 fw-bolder mb-0">
                                <span className="text-gradient d-inline">Portofolio</span>
                            </h1>
                        </div>
                        <div className="row gx-5 justify-content-center">
                            {!!posting && posting ? (
                                posting.map((item, i) => (
                                <div key={i} className="col-lg-4 col-md-6 portfolio-item mt-2">
                                    <div className="card">
                                    <img src={'https://backend.merapikoding.com/assets/image/' + item.gambar[0]} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="fw-bolder text-center">  <a style={{ color: 'black' }} href={"/DetailProject/" + item.id} > {item.nama}</a> </h5>
                                        <p style={{ textAlign: 'justify' }} className="card-text">{`${item.keterangan.slice(0, 120)}...`}</p>
                                        <a href={"/DetailProject/" + item.id} className="btn btn-outline-success">Detail</a>
                                    </div>
                                    </div>
                                </div>
                                ))) : (<div>Tidak ada data</div>)}
                        </div>

                        <div className="pagination ms-2">
                                <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                                {pageNumbers}
                                <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
                         </div>
                    </div>
                </section>
            </main>

            {/* ======= Footer ======= */}
            <footer id="footer">
                <div className="container footer-bottom clearfix">
                <div className="copyright">
                    © Copyright{" "}
                    <strong>
                    <span>Merapi Koding</span>
                    </strong>
                    . All Rights Reserved
                </div>
                <div className="credits">
                    Designed by <a href="https://merapikoding.com/">MerapiKoding</a>
                </div>
                </div>
            </footer>
            {/* End Footer */}
        </div>
    );
}

export default Project;
